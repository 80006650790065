<script>
	import { Bar } from "vue-chartjs";
	import "chartjs-plugin-stacked100";
	export default {
		name: "BarChart",
		extends: Bar,
		props: ["labels", "data", "totalOrders"],
		mounted() {
			this.renderBarChart();
		},
		computed: {
			chartData: function() {
				//console.log("data computed: ", this.data);
				return this.data;
			},
			chartLabels: function() {
				//console.log("labels computed: ", this.labels);
				return this.labels;
			},
		},
		methods: {
			renderBarChart() {
				this.renderChart(
					{
						labels: this.chartLabels,
						datasets: this.chartData,
					},
					{
						plugins: {
							stacked100: { enable: false },
							datalabels: {
								display: true,
							},
						},
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									stacked: true,
									barPercentage: 1,
								},
							],
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
										stepSize: 20,
									},
									stacked: true,
								},
							],
						},
					}
				);
			},
		},
		watch: {
			data: function() {
				this.$data._chart.destroy();
				this.renderBarChart();
			},
		},
	};
</script>
