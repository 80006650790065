<script>
	import { Pie } from "vue-chartjs";
	import ChartDataLabels from "chartjs-plugin-datalabels";

	export default {
		extends: Pie,
		name: "DonutChart",
		props: ["data", "options", "labelType", "parentHandler", "totalOrders"],
		plugins: [ChartDataLabels],
		mounted() {
			this.renderDonutChart();
		},
		computed: {
			chartData: function() {
				return this.data;
			},
		},
		methods: {
			//Función que controla el evento del gráfico
			// handleClick(point, event) {
			// 	const item = event[0];

			// 	let objLabel = this.chartData[item._index];

			// 	//Invocación de función padre que captura el item clickeado
			// 	this.$props.parentHandler(objLabel);
			// },
			renderDonutChart: function() {
				let newChartData = {
					labels: [],
					datasets: [
						{
							data: [],
							backgroundColor: [],
						},
					],
				};

				//Construye dataset requerido por librería
				let dataSet = this.data;

				dataSet.map((item) => {
					let itemKey = item.categoria;
					let itemVal = item.cuenta;
					let itemBgColor = item.backgroundColor;

					newChartData.labels.push(itemKey);
					newChartData.datasets[0].data.push(itemVal);
					newChartData.datasets[0].backgroundColor.push(itemBgColor);
				});

				//Crea una nueva instancia del gráfico
				this.renderChart(newChartData, {
					responsive: true,
					maintainAspectRatio: true,
					legend: {
						position: "top",
						align: "start",
						boxWidth: 2,
					},
					//Plugin que muestra label sobre una porción del gráfico
					plugins: {
						datalabels: {
							formatter: (value, ctx) => {
								let totalOrders = parseInt(this.$props.totalOrders);

								let percentage = (value * 100) / totalOrders;

								switch (this.labelType) {
									case "value":
										return value;
									case "percentage":
										if (percentage >= 4) {
											return percentage.toFixed(2).replace(".", ",") + "%";
										} else {
											return null;
										}

									default:
										return value;
								}
							},
							color: "#757363",
							font: {
								weight: "bold",
								size: 18,
							},
						},
					},
				});
			},
		},
		watch: {
			chartData: function() {
				this.renderDonutChart();
			},
		},
	};
</script>
