<template>
	<v-container fluid>
		<v-row justify="start" align="center">
			<v-col sm="12" md="6" lg="5" xl="4">
				<h1>Dashboard Acumulado Mensual</h1>
			</v-col>
			<v-col sm="4" md="3" lg="3" xl="5">
				<div v-if="!secondPageLoading && !firstPageLoading">
					<div class="d-inline mr-3">
						<v-btn color="#311def" dark small icon @click="pageNumber = 1">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
					</div>
					<p class="d-inline body-2 font-italic">Página {{ pageNumber }} de 2</p>
					<div class="d-inline ml-3">
						<v-btn color="#311def" dark smallv icon @click="pageNumber = 2">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</div>
				</div>
				<div v-else>
					<!-- <v-progress-circular :width="4" color="red" indeterminate></v-progress-circular> -->
				</div>
			</v-col>
			<v-col sm="3" md="12" lg="2" xl="2">
				<div class="d-inline-flex pa-2">
					<div class="justify-start">
						<v-btn
							@click="resetFilters()"
							:disabled="firstPageLoading"
							class="white--text mx-1"
							color="grey darken-1"
							>Limpiar Filtros<v-icon class="ml-3">mdi-refresh-circle</v-icon>
						</v-btn>
					</div>
					<div class="justify-end">
						<v-btn
							@click="getReportData()"
							v-if="!isLoadingCSV"
							class="white--text mx-1"
							color="grey darken-1"
							>Exportar CSV<v-icon class="ml-3">mdi-file-document</v-icon>
						</v-btn>
						<v-progress-circular
							v-if="isLoadingCSV"
							:width="4"
							color="#311def"
							indeterminate
						></v-progress-circular>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row v-if="!loadingData" justify="start" align="center">
			<!-- Filters Section -->
			<v-col>
				<v-menu
					ref="dateMenu"
					v-model="dateMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on }" class="text-center" justify="center">
						<v-text-field
							class="mt-n5 caption"
							v-model="formattedReportDates"
							prepend-icon="mdi-calendar"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						:min="minDate"
						:max="maxDate"
						range
						show-current
						v-model="reportDates"
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="dateMenu = false">Cancelar</v-btn>
						<v-btn text color="primary" @click="getSelectedDates()">OK</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="1">
				<v-combobox
					:label="'Estado'"
					:items="estadoSelectorItems"
					v-model="selectedEstado"
					@change="evalSelectorFilter(selectedEstado)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col>
				<v-combobox
					:label="'Comuna'"
					:items="comunaSelectorItems"
					v-model="selectedComuna"
					@change="evalSelectorFilter(selectedComuna)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col>
				<v-combobox
					:label="'Tipo Entrega'"
					:items="entregaSelectorItems"
					v-model="selectedEntrega"
					@change="evalSelectorFilter(selectedEntrega)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col>
				<v-combobox
					:label="'Origen'"
					:items="origenSelectorItems"
					v-model="selectedOrigen"
					@change="evalSelectorFilter(selectedOrigen)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col>
				<v-combobox
					:label="'Tipo Venta'"
					:items="tipoVentaSelectorItems"
					v-model="selectedTipoVenta"
					@change="evalSelectorFilter(selectedTipoVenta)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>

			<v-col>
				<v-combobox
					:label="'Causa Cancelación'"
					:items="causaCancelacionSelectorItems"
					v-model="selectedCausaCancelacion"
					@change="evalSelectorFilter(selectedCausaCancelacion)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col cols="1">
				<v-combobox
					:label="'Periferia'"
					:items="periferiaSelectorItems"
					v-model="selectedPeriferia"
					@change="evalSelectorFilter(selectedPeriferia)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<v-col cols="1">
				<v-combobox
					:label="'Entrega Futura'"
					:items="entregaFuturaSelectorItems"
					v-model="selectedEntregaFutura"
					@change="evalSelectorFilter(selectedEntregaFutura)"
					outlined
					dense
					item-color="#311def"
					class="caption"
				></v-combobox>
			</v-col>
			<!-- End Filters Section -->
		</v-row>

		<v-stepper v-if="showGraficos" v-model="pageNumber" class="elevation-0" :non-linear="true">
			<!-- First Page Dashboard -->

			<v-stepper-content step="1">
				<v-layout justify-start>
					<v-flex xs5 sm5 md5 lg5 xl3>
						<v-card height="100%" class="pl-4 pt-5">
							<v-card-text>
								<v-row justify="center" align="center">
									<!-- DonutChart -->
									<DonutChart
										v-if="!loadingData && !firstPageLoading"
										:data="donutChartItems"
										:labelType="'percentage'"
										:totalOrders="totalOrders"
									/>
									<!-- End DonutChart -->
									<v-progress-circular
										v-if="loadingData && firstPageLoading"
										:size="100"
										:width="5.5"
										color="grey lighten-1"
										indeterminate
									></v-progress-circular>
								</v-row>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex xs7 sm7 md7 lg7 xl9>
						<v-card height="100%" class="ml-6">
							<v-card-text>
								<div class="mt-n3 mb-3">
									<!-- CardIndicator -->
									<v-chip
										class="px-4 pt-4 text-start"
										color="#5fcc60"
										text-color="white"
										label
										x-large
									>
										<v-row align-content="center">
											<v-col>
												<v-progress-circular
													v-if="loadingData && firstPageLoading"
													:size="25"
													:width="2"
													color="grey lighten-1"
													indeterminate
													class="mt-n6"
												></v-progress-circular>
												<div v-else>
													<p class="caption">Entregado %</p>
													<p class="title mt-n5">{{ cardStats.percentEntregados }}%</p>
													<p class="subtitle-2 font-italic mt-n6">
														{{ cardStats.countEntregados }}
													</p>
												</div>
											</v-col>
										</v-row>
									</v-chip>
									<!-- End CardIndicator -->

									<!-- CardIndicator -->
									<v-chip
										class="ma-3 px-4 pt-4 text-start"
										color="grey darken-2"
										text-color="white"
										label
										x-large
									>
										<v-row align-content="center">
											<v-col>
												<v-progress-circular
													v-if="loadingData && firstPageLoading"
													:size="25"
													:width="2"
													color="grey lighten-1"
													indeterminate
													class="mt-n6"
												></v-progress-circular>
												<div v-else>
													<p class="caption">Entregado + Gestión</p>
													<p class="title mt-n5">{{ cardStats.percentTotal }}%</p>
													<p class="subtitle-2 font-italic mt-n6">
														{{ cardStats.countTotal }}
													</p>
												</div>
											</v-col>
										</v-row>
									</v-chip>
									<!-- End CardIndicator -->

									<!-- CardIndicator -->
									<v-chip
										class="px-4 pt-4 text-start"
										color="grey darken-2"
										label
										text-color="white"
										x-large
									>
										<v-row align-content="center">
											<v-col>
												<v-progress-circular
													v-if="loadingData && firstPageLoading"
													:size="25"
													:width="2"
													color="grey lighten-1"
													indeterminate
													class="mt-n6"
												></v-progress-circular>

												<div v-else>
													<p class="caption mt-n3">Total</p>

													<p class="headline mt-n5">{{ formatNumber(totalOrders) }}</p>
												</div>
											</v-col>
										</v-row>
									</v-chip>
									<!-- End CardIndicator -->
								</div>

								<!-- Bar Chart -->
								<BarChart
									ref="bar-chart"
									chart-id="bar-chart"
									v-if="!loadingData && !firstPageLoading"
									:labels="barChartLabels"
									:data="barChartItems"
									:totalOrders="totalOrders"
								/>
								<v-progress-circular
									class="mt-12"
									v-if="loadingData && firstPageLoading"
									:size="100"
									:width="5.5"
									color="grey lighten-1"
									indeterminate
								></v-progress-circular>
								<!-- End Bar Chart -->
							</v-card-text>
						</v-card>
					</v-flex>
				</v-layout>
			</v-stepper-content>
			<!-- End First Page -->

			<!-- Second Page Dashboard -->
			<v-stepper-content step="2">
				<v-layout justify-start>
					<v-flex xs12 sm12 md12 lg12 xl12>
						<v-lazy
							v-model="isSecondChartActive"
							:options="{
								threshold: 0.5,
							}"
							transition="fade-transition"
						>
							<ExtendedBarChart
								ref="extended-bar-chart"
								chart-id="extended-bar-chart"
								v-if="!secondPageLoading"
								:labels="extendedBarChartLabels"
								:data="extendedBarChartItems"
								:totalOrders="totalOrders"
							/>
						</v-lazy>
					</v-flex>
				</v-layout>
				<v-layout justify-start class="mt-6">
					<v-flex xs12 sm12 md12 lg12 xl12>
						<v-lazy
							v-model="isSecondChartActive"
							:options="{
								threshold: 0.5,
							}"
							transition="fade-transition"
						>
							<v-data-table
								v-if="!secondPageLoading"
								dense
								:single-expand="true"
								:headers="tableHeaders"
								:items="tableItems"
								:items-per-page="15"
								:disable-filtering="true"
								:fixed-header="true"
								:loading="secondPageLoading"
								class="elevation-1"
							></v-data-table>
						</v-lazy>
					</v-flex>
				</v-layout>
			</v-stepper-content>
			<!-- End Second Page -->
		</v-stepper>

		<!-- Generic Snackbar -->
		<v-snackbar class="pa-2" color="#311def" :timeout="6000" :top="true" v-model="displaySnackBar">
			<v-row justify="center" align="center" class="px-6 pt-4">
				<p class="subtitle-1">{{ textSnackBar }}</p>
				<!-- <v-btn text @click="snackbar = false">OK</v-btn> -->
			</v-row>
		</v-snackbar>
		<!-- End Genberic Snackbar -->
	</v-container>
</template>

<style>
	.v-label {
		font-size: 90% !important;
	}
</style>

<script>
	import axios from "axios";
	import moment from "moment";
	import filters from "../charts/filtrosReporte.json";
	import DonutChart from "../charts/DonutChart";
	import BarChart from "../charts/BarChart";
	import ExtendedBarChart from "../charts/ExtendedBarChart";

	export default {
		computed: {},
		components: {
			DonutChart,
			BarChart,
			ExtendedBarChart,
		},
		data: () => ({
			showGraficos: true,
			cardStats: {},
			countOrders: 0,
			defaultDate: "",
			loadingData: true,
			reportDaysOnPast: 16,
			displaySnackBar: false,
			textSnackBar: "",

			//date picker props
			minDate: "",
			maxDate: "",
			reportDates: [],
			formattedReportDates: "",
			dateMenu: false,
			maxRangeValue: 32,

			//first page props
			firstPageLoading: true,
			maxBarChartItems: 0,
			barChartItems: [],
			barChartLabels: [],
			donutChartItems: [],
			diasReporte: [],
			reportItems: [],
			totalOrders: 0,

			//second page chart
			secondPageLoading: true,
			isSecondChartActive: false,
			maxFailureItems: 12,
			extendedBarChartItems: [],
			extendedBarChartLabels: [],
			tableItems: [],
			tableHeaders: [
				{ text: "Caso", value: "caso", sortable: true },
				{ text: "Motivo Fallo FM", value: "failedReason", sortable: true },
				{ text: "Comuna", value: "comuna", sortable: true },
				{ text: "Origen", value: "origen", sortable: true },
				{ text: "Tipo Entrega", value: "tipoEntrega", sortable: true },
				{ text: "Tipo Venta", value: "tipoVenta", sortable: true },
			],

			//selectorsItems
			dateSelectorItems: [],
			estadoSelectorItems: [],
			comunaSelectorItems: [],
			entregaSelectorItems: [],
			origenSelectorItems: [],
			tipoVentaSelectorItems: [],
			causaCancelacionSelectorItems: [],
			periferiaSelectorItems: [],
			entregaFuturaSelectorItems: [],

			//selectors current value
			selectedDate: "",
			selectedEstado: "",
			selectedComuna: "",
			selectedEntrega: "",
			selectedOrigen: "",
			selectedTipoVenta: "",
			selectedCausaCancelacion: "",
			selectedPeriferia: "",
			selectedEntregaFutura: "",

			//stepper props
			pageNumber: 1,

			//csv report props
			isCSVData: false,
			isLoadingCSV: false,
			reportHeaders: [],
			reportData: "",
		}),
		methods: {
			cleanData() {
				this.barChartItems = [];
				this.barChartLabels = [];
				this.donutChartItems = [];
				this.reportItems = [];
				this.extendedBarChartItems = [];
				this.extendedBarChartLabels = [];
				this.tableItems = [];
			},
			formatNumber(value) {
				var valor = String(value);
				let formatted = "";

				for (var i = 1; i <= valor.length; i++) {
					formatted = valor[valor.length - i].toString() + formatted;

					if (i % 3 == 0 && i != valor.length) {
						formatted = "." + formatted;
					}
				}

				return formatted;
			},
			toPercentage(value, total) {
				return ((value * 100) / total).toFixed(2).replace(".", ",");
			},
			toggleLoading() {
				this.loadingData = true;
				this.firstPageLoading = true;
				this.secondPageLoading = true;
			},
			getSlicedArray(array, itemsToSlice) {
				let newArray = array.slice(0, itemsToSlice);
				return newArray;
			},
			getDaysInitEnd(datesObject) {
				let initDate = moment(datesObject.init);
				let endDate = moment(datesObject.end);
				let dateDiff = initDate.diff(endDate, "days");

				return parseInt(dateDiff);
			},
			resetFilters() {
				this.selectedDate = "";
				this.selectedEstado = "";
				this.selectedComuna = "";
				this.selectedEntrega = "";
				this.selectedOrigen = "";
				this.selectedTipoVenta = "";
				this.selectedCausaCancelacion = "";
				this.selectedPeriferia = "";
				this.selectedEntregaFutura = "";

				this.initReportDates();
				this.getClaroData();
			},
			buildDatesArray() {
				try {
					this.diasReporte = [];

					let initDate = moment(this.reportDates[0]);
					let endDate = moment(this.reportDates[1]);

					let datesDiff = this.getDaysInitEnd({ init: initDate, end: endDate }) * -1; //returns diff as negative number;
					let chartDatesArray = new Array();

					for (let index = 0; index < datesDiff + 1; index++) {
						const auxDateItem = moment()
							.subtract(index, "days")
							.format("YYYY-MM-DD");

						chartDatesArray.push(auxDateItem);
					}

					this.diasReporte = chartDatesArray;
				} catch (error) {
					console.log("buildDatesArray -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			buildDatesLabels() {
				this.barChartLabels = [];

				let initDate = moment(this.reportDates[0]);
				let endDate = moment(this.reportDates[1]);

				let datesDiff = this.getDaysInitEnd({ init: initDate, end: endDate }) * -1; //returns diff as negative number;

				let maxLabels;

				//uses the max bars quantity or the diff between two dates
				if (datesDiff < this.reportDaysOnPast) {
					maxLabels = datesDiff + 1;
				} else {
					maxLabels = this.reportDaysOnPast;
				}

				endDate.add(1, "days");

				const chartDatesArray = Array(maxLabels)
					.fill()
					.map(() => endDate.subtract(1, "d").format("YYYY-MM-DD"));

				return chartDatesArray;
			},
			initReportDates() {
				try {
					//set max and min dates for picker
					// let pickerMinDate = moment()
					// 	.subtract(62, "days")
					// 	.format("YYYY-MM-DD");

					let pickerMinDate = "2020-01-01";

					let pickerMaxDate = moment()
						.subtract(0, "days")
						.format("YYYY-MM-DD");

					this.minDate = pickerMinDate;
					this.maxDate = pickerMaxDate;

					// //set default date today
					// this.defaultDate = moment()
					// 	.subtract(0, "days")
					// 	.format("YYYY-MM-DD");

					// this.selectedDate = this.selectedDate == "" ? this.defaultDate : this.selectedDate;

					//DEFAULT DATE = 1ST DAY OF PAST MONTH
					// let defaultInitDate = moment().subtract(1, "month");

					// let defaultEndDate = moment();

					let defaultInitDate = moment().subtract(31, "days");
					let defaultEndDate = moment();

					this.reportDates[0] = defaultInitDate.format("YYYY-MM-DD");
					this.reportDates[1] = defaultEndDate.format("YYYY-MM-DD");

					// this.reportDates[0] = "2020-07-15";
					// this.reportDates[1] = "2020-07-16";

					//build datesArray for charts usage
					this.buildDatesArray({ init: this.reportDates[0], end: this.reportDates[1] });

					let auxInit = moment(this.reportDates[0]);
					let auxEnd = moment(this.reportDates[1]);

					this.formattedReportDates = auxInit.format("MMM-DD") + " - " + auxEnd.format("MMM-DD");
				} catch (error) {
					console.log("initReportDates -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			initSelectors() {
				try {
					this.estadoSelectorItems = filters.estados_items;
					this.comunaSelectorItems = filters.comunas_items;
					this.entregaSelectorItems = filters.tipos_entrega_items;
					this.origenSelectorItems = filters.origenes_items;
					this.tipoVentaSelectorItems = filters.tipos_venta_items;
					this.causaCancelacionSelectorItems = filters.causas_cancelacion_items;
					this.periferiaSelectorItems = filters.periferia_items;
					this.entregaFuturaSelectorItems = filters.entrega_futura_items;
				} catch (error) {
					console.log("initSelectors -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			getSelectedDates() {
				try {
					this.$refs.dateMenu.save(this.reportDates);

					let initDate = moment(this.reportDates[0]);
					let endDate = moment(this.reportDates[1]);
					let dateDiff = endDate.diff(initDate, "days");

					if (dateDiff > 0) {
						this.maxBarChartItems = this.reportDaysOnPast;
					} else {
						this.maxBarChartItems = 1;
					}

					// if (dateDiff < this.maxRangeValue) { //rango maximo de fechas deshabilitado
					//dates to timestamp
					let unixInitDate = initDate.valueOf();
					let unixEndDate = endDate.valueOf();

					//end date needs to be greater than init
					if (unixInitDate > unixEndDate) {
						let auxVar = unixEndDate;

						//the endDate will always be greater than initDate
						unixEndDate = unixInitDate;
						unixInitDate = auxVar;
					}

					let newInitDate = moment.unix(unixInitDate / 1000);
					let newEndDate = moment.unix(unixEndDate / 1000);

					this.reportDates[0] = newInitDate.format("YYYY-MM-DD");
					this.reportDates[1] = newEndDate.format("YYYY-MM-DD");

					this.formattedReportDates = newInitDate.format("MMM-DD") + ", " + newEndDate.format("MMM-DD");

					this.dateMenu = false;
					this.getClaroData();
					// } else {
					// 	this.textSnackBar = "El rango máximo de selección es de 31 días.";
					// 	this.displaySnackBar = true;
					// 	this.dateMenu = true;
					// }
				} catch (error) {
					console.log("getSelectedDates -> error", error);
					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			evalSelectorFilter(input) {
				try {
					input == null || input === undefined ? false : this.getClaroData();
				} catch (error) {
					console.log("evalSelectorFilter -> error", error);
					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			buildFailedItemsChart(dataSetArray) {
				try {
					let arrMotivosFallo = [];
					let auxArray = [];

					//build array with unique values
					dataSetArray.map(function(item) {
						let motivoFalloItem = item.falla_origen;

						if (motivoFalloItem !== "" && motivoFalloItem != null) {
							auxArray.push(motivoFalloItem.toUpperCase());
						}
					});

					const uniqueFailureItemsSet = new Set(auxArray);
					const uniqueFailureItemsArray = [...uniqueFailureItemsSet];

					auxArray = uniqueFailureItemsArray;

					//build array with objects
					auxArray.map(function(itemFallo, indexFallo) {
						let falloItem = new Object();
						falloItem = {
							MotivoFallo: itemFallo,
							Cantidad: 0,
						};
						arrMotivosFallo.push(falloItem);
					});

					arrMotivosFallo.map(function(itemFallo, indexFallo) {
						dataSetArray.map(function(itemOrden, indexOrden) {
							let falloOrden = itemOrden.falla_origen;

							if (falloOrden == itemFallo.MotivoFallo) {
								itemFallo.Cantidad += 1;
							}
						});
					});

					//sorting the failure items
					arrMotivosFallo.sort((itemA, itemB) => (itemA.Cantidad < itemB.Cantidad ? 1 : -1));

					//build dataset
					let dataArray = [];
					let chartLabels = [];
					let maxFailureItems = this.maxFailureItems;

					for (let index = 0; index < maxFailureItems; index++) {
						const item = arrMotivosFallo[index];

						if (item !== undefined) {
							dataArray.push(parseInt(item.Cantidad));
							chartLabels.push(item.MotivoFallo);
						}
					}

					let dataSetExtendedBarChart = [
						{
							label: "Total Casos",
							backgroundColor: "#c3c3c3",
							data: dataArray,
						},
					];

					this.extendedBarChartItems = dataSetExtendedBarChart;
					this.extendedBarChartLabels = chartLabels;
				} catch (error) {
					console.log("buildExtendedBarChart -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			buildFailedItemsTable(dataSetArray) {
				try {
					let context = this;

					dataSetArray.map(function(item, index) {
						if (item.falla_origen !== "") {
							let tableItem = {
								caso: item.caso,
								failedReason: item.falla_origen != null ? item.falla_origen.toUpperCase() : "",
								comuna: item.comuna != null ? item.comuna.toUpperCase() : "",
								origen: item.origen != null ? item.origen.toUpperCase() : "",
								tipoEntrega: item.tipo_entrega != null ? item.tipo_entrega.toUpperCase() : "",
								tipoVenta: item.tipo_venta != null ? item.tipo_venta.toUpperCase() : "",
							};

							context.tableItems.push(tableItem);
						}
					});
				} catch (error) {
					console.log("buildTableData -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			buildDonutChart(dataSetObject) {
				try {
					let totalEntregados = dataSetObject.entregados;
					let totalGestionados = dataSetObject.gestionados;
					let totalPerifFutura = dataSetObject.perifFutura;
					let totalSinGestion = dataSetObject.sinGestion;

					let dataSetDonutChart = [
						{
							categoria: "Entregados",
							cuenta: totalEntregados,
							backgroundColor: "#5fcc60",
						},
						{
							categoria: "En Gestión - Periferia / Entrega Futura",
							cuenta: totalPerifFutura,
							backgroundColor: "#f9a800",
						},
						{
							categoria: "Sin Gestión",
							cuenta: totalSinGestion,
							backgroundColor: "#C62828",
						},
						{
							categoria: "En Gestión",
							cuenta: totalGestionados,
							backgroundColor: "#fce651",
						},
					];

					this.donutChartItems = dataSetDonutChart;
				} catch (error) {
					console.log("buildDonutChart -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					setTimeout(function() {
						this.resetFilters();
					}, 2000);
				}
			},
			buildStackedBarChart(dataSetObject) {
				try {
					//reportDaysOnPast is a default value, maxBarChartItems is a calculated member in getSelectedDates()
					let maxBarChartItems = this.maxBarChartItems == 0 ? this.reportDaysOnPast : this.maxBarChartItems;

					let arrEntregados = this.getSlicedArray(dataSetObject.itemsEntregados, maxBarChartItems);
					let arrGestionados = this.getSlicedArray(dataSetObject.itemsGestionados, maxBarChartItems);
					let arrPerifFuturo = this.getSlicedArray(dataSetObject.itemsPerifFutura, maxBarChartItems);
					let arrSinGestion = this.getSlicedArray(dataSetObject.itemsSinGestion, maxBarChartItems);

					let chartLabels = this.buildDatesLabels();

					let dataSetBarChart = [
						{
							label: "Entregado",
							backgroundColor: "#5fcc60",
							data: arrEntregados,
						},
						{
							label: "En Gestión - Periferia / Entrega Futura",
							backgroundColor: "#f9a800",
							data: arrPerifFuturo,
						},
						{
							label: "Sin Gestión",
							backgroundColor: "#C62828",
							data: arrSinGestion,
						},
						{
							label: "En Gestión",
							backgroundColor: "#fce651",
							data: arrGestionados,
						},
					];

					this.barChartItems = dataSetBarChart;
					this.barChartLabels = chartLabels;
				} catch (error) {
					console.log("buildStackedBarChart -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			buildCharts(responseData) {
				try {
					let arrEntregados = [];
					let arrGestionados = [];
					let arrPerifFutura = [];
					let arrSinGestion = [];

					let totalEntregados = 0;
					let totalGestionados = 0;
					let totalPerifFutura = 0;
					let totalSinGestion = 0;
					let totalOrders = 0;

					responseData = Object.values(responseData); //take object's values and creates array
					responseData.reverse(); //displays descendent dates

					responseData.map(function(itemDiario, indexDiario) {
						let cantEntregados = parseInt(itemDiario.entregado);
						let cantGestionados = parseInt(itemDiario.en_gestion);
						let cantPerifFuturas = parseInt(itemDiario.Gestion_Periferia_Entrega_fac);
						let cantSinGestion = parseInt(itemDiario.sin_gestion);

						arrEntregados.push(cantEntregados);
						arrGestionados.push(cantGestionados);
						arrPerifFutura.push(cantPerifFuturas);
						arrSinGestion.push(cantSinGestion);

						totalEntregados += cantEntregados;
						totalGestionados += cantGestionados;
						totalPerifFutura += cantPerifFuturas;
						totalSinGestion += cantSinGestion;

						let subTotal = cantEntregados + cantGestionados + cantPerifFuturas + cantSinGestion;

						totalOrders += subTotal;
					});

					/* First Page Data */
					let datasetBarchart = {
						itemsEntregados: arrEntregados,
						itemsGestionados: arrGestionados,
						itemsPerifFutura: arrPerifFutura,
						itemsSinGestion: arrSinGestion,
					};

					let datasetDonut = {
						entregados: totalEntregados,
						gestionados: totalGestionados,
						perifFutura: totalPerifFutura,
						sinGestion: totalSinGestion,
					};

					this.buildStackedBarChart(datasetBarchart);
					this.buildDonutChart(datasetDonut);

					//color cards indicators
					this.totalOrders = totalOrders;
					this.cardStats = {
						percentEntregados: this.toPercentage(totalEntregados, totalOrders),
						percentGestionados: this.toPercentage(totalGestionados, totalOrders),
						percentEntregadosGestionados: this.toPercentage(
							totalEntregados + totalGestionados,
							totalOrders
						),
						percentTotal: this.toPercentage(
							totalEntregados + totalGestionados + totalPerifFutura + totalSinGestion,
							totalOrders
						),
						countEntregados: this.formatNumber(totalEntregados),
						countGestionados: this.formatNumber(totalGestionados),
						countEntregadosGestionados: this.formatNumber(totalEntregados + totalGestionados),
						countTotal: this.formatNumber(
							totalEntregados + totalGestionados + totalPerifFutura + totalSinGestion
						),
					};
					/* End First Page Data */
				} catch (error) {
					console.log("buildCharts -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
			exportToCSV() {
				try {
					let reportInitDate = this.reportDates[0];
					let reportEndDate = this.reportDates[1];

					let csvFileName = `Reporte_${reportInitDate}_${reportEndDate}.csv`;
					let csvData = this.reportData;
					let csvElement = document.createElement("a");
					csvData = csvData.replace('#',' ')
					csvElement.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData);
				// 	csvElement.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData);
					csvElement.download = csvFileName;
					csvElement.click();
				} catch (error) {
					console.log("exportToCSV -> error", error);
					this.textSnackBar = "No se ha podido generar el reporte solicitado.";
					this.displaySnackBar = true;
				}
			},
			buildDataParamObject() {
				let reportInitDate = this.reportDates[0];
				let reportEndDate = this.reportDates[1];
				let callParams = {
					fin: reportEndDate,
					inicio: reportInitDate,
					cliente: "claro",
					origen:
						this.selectedOrigen == "No Filtrar" || this.selectedOrigen == null ? "" : this.selectedOrigen,
					estado:
						this.selectedEstado == "No Filtrar" || this.selectedEstado == null ? "" : this.selectedEstado,
					comuna:
						this.selectedComuna == "No Filtrar" || this.selectedComuna == null ? "" : this.selectedComuna,
					tipoVenta:
						this.selectedTipoVenta == "No Filtrar" || this.selectedTipoVenta == null
							? ""
							: this.selectedTipoVenta,
					tipoEntrega:
						this.selectedEntrega == "No Filtrar" || this.selectedEntrega == null
							? ""
							: this.selectedEntrega,
					causaCancelacion:
						this.selectedCausaCancelacion == "No Filtrar" || this.selectedCausaCancelacion == null
							? ""
							: this.selectedCausaCancelacion,

					periferia:
						this.selectedPeriferia == "No Filtrar" || this.selectedPeriferia == null
							? ""
							: this.selectedPeriferia,

					entregaFutura:
						this.selectedEntregaFutura == "No Filtrar" || this.selectedEntregaFutura == null
							? ""
							: this.selectedEntregaFutura,
				};

				return callParams;
			},
			getReportData() {
				let self = this;

				let callParams = this.buildDataParamObject();

				//CSV DATA FROM CLOUD FUNCTION
				this.reportData = "";
				this.isLoadingCSV = true;

				axios
					.post(
						"https://us-central1-rayo-dev.cloudfunctions.net/claro_acumulado_diario/ReporteData",
						callParams
					)
					.then((csvDataResponse) => {
						let csvData = csvDataResponse.data;

						self.reportData = csvData;
						self.isCSVData = true;
						self.isLoadingCSV = false;

						self.exportToCSV();
					})
					.catch(function(erorReportData) {
						console.log("getReportData -> erorReportData", erorReportData);
					});
			},

			getClaroData() {
				try {
					this.toggleLoading();
					this.cleanData();

					let callParams = this.buildDataParamObject();

					console.log("getClaroData -> parametros", callParams);

					//FIRST PAGE DATA FOR BOTH CHARTS
					axios
						.post(
							"https://us-central1-rayo-dev.cloudfunctions.net/claro_acumulado_diario/ClaroResumen",
							callParams
						)
						.then((firstPageResponse) => {
							let firstPageDatasetArray = Object.values(firstPageResponse.data[0]);

							if (firstPageDatasetArray.length > 0) {
								this.buildCharts(firstPageDatasetArray);

								this.firstPageLoading = false;
								this.loadingData = false;
							} else {
								this.textSnackBar =
									"No se han encontrado registros para la combinación de filtros seleccionada. Recargando datos con filtros por defecto...";
								this.displaySnackBar = true;
								this.resetFilters();
							}
						})
						.catch(function(errorResumenData) {
							console.log("getClaroData -> errorResumenData", errorResumenData);
						});

					//SECOND PAGE DATA FOR CHART AND TABLE
					axios
						.post(
							"https://us-central1-rayo-dev.cloudfunctions.net/claro_acumulado_diario/ClaroDetalle",
							callParams
						)
						.then((dataDetalle) => {
							let secondPageDatasetArray = Object.values(dataDetalle.data[0]);

							this.buildFailedItemsChart(secondPageDatasetArray);
							this.buildFailedItemsTable(secondPageDatasetArray);
							this.secondPageLoading = false;
							this.isSecondChartActive = false;
						})
						.catch(function(errorDetalle) {
							console.log("getClaroData -> errorDetalle", errorDetalle);
						});
				} catch (error) {
					console.log("mountedCatch -> error", error);

					this.textSnackBar =
						"No se han podido obtener los datos necesarios para generar los gráficos. Reintentando recuperar datos...";
					this.displaySnackBar = true;

					this.resetFilters();
				}
			},
		},
		mounted() {
			this.initReportDates();
			this.initSelectors();
			this.getClaroData();
		},
	};
</script>
